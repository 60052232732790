import { downloadCategories } from './constants';

export function filterCategories(category) {
    //console.log('Filtering categories for: ' + category);

    return downloadCategories.filter(item => {
        if (item.name === category){
            //console.log('Wiki: ' + item.wiki)
            return true;
        } else {
            return false;
        }
    });
  }

export function filterChannels(channels, wikiCategory) {
    //console.log('Filtering channels for: ' + wikiCategory);
    //console.log(channels);

    return channels.filter(item => {
        if (wikiCategory === 'All'){
            return true;
        } else {
            //console.log(item["Channel Info"].items[0]?.topicDetails?.topicCategories)
            return item["Channel Info"].items[0]?.topicDetails?.topicCategories.includes(wikiCategory);
        }
    });
  }
