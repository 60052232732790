import { useState, useEffect, useMemo } from 'react';
import { Stack, Box } from '@mui/material';
import { NavSidebar, DownloadSidebar, Channels, BottomBar } from '.';
import { fetchFromAPI } from '../utils/fetchFromAPI';
import { Helmet } from 'react-helmet';
import { filterCategories, filterChannels } from '../utils/filters';
import { UserAuth } from '../context/AuthContext';

const DownloadFeed = () => {

  const {user} = UserAuth();

  const [selectedCategory, setSelectedCategory] = useState('All');

  const [channels, setChannels] = useState(null);
  
  const [filteredChannels, setFilteredChannels] = useState(null);

  const filteredCategories = useMemo(
    () => filterCategories(selectedCategory),
    [selectedCategory]
  );

  useEffect(()=> {
    
    if (selectedCategory === 'All'){
      if(!channels){
          if(Object.keys(user).length !== 0) {
            console.log('Fetching downloads from API')
            fetchFromAPI(`getDownloadsFile`)
              .then((data) => setChannels(data.Downloads))
          }
        }
    } else if (selectedCategory !== 'All'){
        setFilteredChannels(filterChannels(channels, filteredCategories[0]["wiki"]))
    }  
    },[user,selectedCategory, channels, filteredCategories]);

  return (
    <Stack sx={{ pb: 7 }}>
        <Helmet>
          <title>YTForensics - Downloads</title>
        </Helmet>
        <NavSidebar />
        <Stack sx={{ flexDirection: { sx: "column", md: "row" } }}>
          <Box sx={{ height: { xs: "auto"}, px: { xs: 0, md: 2 } }}>
            <DownloadSidebar selectedCategory = {selectedCategory} setSelectedCategory = {setSelectedCategory} />
          </Box>
          <Box sx={{ overflowY: "auto", height: "90vh", flex: 2 }}>
            {selectedCategory === 'All' && <Channels channels={channels} />}
            {selectedCategory !== 'All' && filteredChannels?.length > 0 && <Channels channels={filteredChannels}/>}
          </Box>
        </Stack>
        <BottomBar/>
    </Stack>
    
    
  );
};

export default DownloadFeed;