import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
//import HomeIcon from '@mui/icons-material/Home';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
//import SettingsIcon from '@mui/icons-material/Settings';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import {Link} from 'react-router-dom';

export default function BottomBar() {

  return (
    <Box sx={{ display: { xs: 'block', md: 'none' }}}>
    <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, pb: 2, backgroundColor: '#000' }}>
      <BottomNavigation
        showLabels
        sx={{ backgroundColor: '#000'}}
      >
        <BottomNavigationAction label="Home" icon={<HomeOutlinedIcon />} sx={{ mt: 1.5, color: '#FC1503'}} LinkComponent={Link} to={'/'}/>
        <BottomNavigationAction label="Settings" icon={<SettingsOutlinedIcon />} sx={{ mt: 1.5, color:'#FC1503'}} LinkComponent={Link} to={'/settings'}/>
      </BottomNavigation>
      </Paper>
    </Box>
  );
}