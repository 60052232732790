import { Stack, Typography, Box } from '@mui/material';
import { Helmet } from 'react-helmet';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import { useEffect, useRef } from 'react';

const Cookies = () => {
  
  const legalContainerRef = useRef(null);

  useEffect(() => {
    const script = document.createElement('script');
    script.id = 'CookieDeclaration';
    script.src = 'https://consent.cookiebot.com/04cae54b-02df-4f98-b2bd-07ad8ccf8099/cd.js';
    script.type = 'text/javascript';
    script.async = true;

    legalContainerRef.current.appendChild(script);

  }, [legalContainerRef]);

  return (
    <Stack mx={1}>
      <Helmet>
          <title>YTForensics - Cookies</title>
        </Helmet>
        <Box sx={{ backgroundColor: 'white'}}>
        <Stack direction="row">
        <IconButton onClick={() => window.history.back()} sx={{ mt: 1.5 }}>
          <ArrowBackIcon sx={{ color: '#FC1503' }}/>
        </IconButton>
        <Typography variant="h3" sx={{ mt: 1.5, color: '#FC1503'}}>
              Cookies
            </Typography>
        </Stack>
        <Typography variant="body2" color='black'>
          <div ref={legalContainerRef} className="legal__container" />
        </Typography>
        </Box>
    </Stack>
        
  );
};

export default Cookies;