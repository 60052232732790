import { Box, CardContent, CardMedia, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const ChannelCard = ({ channelDetail }) => (
  <Box
    sx={{
      boxShadow: 'none',
      borderRadius: '20px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: { xs: '356px', md: '320px' },
      height: '326px',
      margin: 'auto',
    }}
  >
    
      <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center', color: '#fff' }}>
      <Link to={`/search/${channelDetail['Download Name']}`} >
        <div style={{ display:'flex', justifyContent:'center' }}><CardMedia
          image={channelDetail['Channel Info'].items[0].snippet.thumbnails.high.url}
          alt={channelDetail['Channel Info'].items[0].snippet.title}
          sx={{borderRadius: '50%', height: '180px', width: '180px', mb: 2 }}
        /></div>
      </Link>
        <Typography sx={{ fontSize: 16 }}>
          {channelDetail['Channel Info'].items[0].snippet.title}
          <CheckCircleIcon sx={{ fontSize: '14px', color: 'gray', ml: '5px' }} />
        </Typography>
        <Link to={`https://www.youtube.com/${channelDetail['Channel Info'].items[0].snippet.customUrl}`} target="_blank" rel="noopener noreferrer">
          <Typography sx={{ fontSize: 14, color: 'red' }}>
            {channelDetail['Channel Info'].items[0].snippet.customUrl}
          </Typography>
        </Link>
        <Typography sx={{ fontSize: 14, color: 'white' }}>
            {channelDetail['Channel Info'].items[0].statistics.videoCount} videos
        </Typography>
        <Typography sx={{ fontSize: 14, color: 'white' }}>
            Download timestamp: {(channelDetail['Download Name']).slice((channelDetail['Download Name']).length - 17)}
        </Typography>
      </CardContent>
    
  </Box>
);

export default ChannelCard;