import axios from 'axios';
import {appCheck} from '../config/firebase';
import {getToken} from 'firebase/app-check';
import {auth} from '../config/firebase';

//const BASE_URL = 'http://127.0.0.1:8000'
//const BASE_URL = 'http://127.0.0.1:5000' //hosting IP address
//const BASE_URL = 'https://yt-forensics-5xivrby7nq-uc.a.run.app/api'
const BASE_URL = '/api' //use when using firebase hosting of cloud run services

export const fetchFromAPI = async (url) => {
  
  let appCheckTokenResponse;
  try {
      appCheckTokenResponse = await getToken(appCheck, /* forceRefresh= */ false);
  } catch (err) {
      // Handle any errors if the token was not retrieved.
      console.log(err);
      return;
  }

  let authTokenResponse;
  try {
      authTokenResponse = await auth.currentUser.getIdToken(/* forceRefresh */ false);
  } catch (err) {
      // Handle any errors if the token was not retrieved.
      console.log(err);
      return;
  }

  const { data } = await axios.get(`${BASE_URL}/${url}`, {
    headers: {
        'X-Firebase-AppCheck': appCheckTokenResponse.token,
        'Authorization': `Bearer ${authTokenResponse}`,
    }
  }).catch(function (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
      }
      console.log(error.config);
    });
      //console.log(data);
    return data;
}