import { Stack} from '@mui/material';
import { downloadCategories } from '../utils/constants';

const DownloadSidebar = ({selectedCategory, setSelectedCategory}) => (
    
    <Stack
        direction="row"
        mt={1}
        mb={1}
        ml={2}
        sx={{
            overflowY: "auto",
            height: { sx: "auto", md: "95%" },
            flexDirection: { md: "column" },
        }}
    >
        
        {downloadCategories.map((category) => (
           <button className = "category-btn"
                    onClick={() => setSelectedCategory(category.name)}
                    style={{
                        background: category.name ===
                        selectedCategory && '#FC1503',
                        color: 'white'
                    }}
                    key = {category.name}
            >
                <span 
                    style={{
                        color: category.name === 
                        selectedCategory ? 'white' : 'red' 
                    }}>{category.icon}</span>
                <span
                    style={{
                        opacity: category.name ===
                        selectedCategory ? '1' : '0.8'
                    }}>{category.name}</span>
           </button> 
        ))}
    </Stack>
)

export default DownloadSidebar