import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Box } from '@mui/material';
import { DownloadFeed, SearchFeed, Signin, HomeFeed, Protected, Cookies, Privacy, Settings, ScrollToTop } from './components';
import { AuthContextProvider } from './context/AuthContext';

const App = () => (
    <BrowserRouter>
        <Box sx={{ backgroundColor: '#000'}}>
            <AuthContextProvider>
                <ScrollToTop>
                    <Routes>
                        <Route path="/" exact element={<Protected><DownloadFeed/></Protected>} />
                        <Route path="/search/:dir" element={<Protected><SearchFeed/></Protected>} />
                        <Route path="/settings" element={<Protected><Settings/></Protected>} />
                        <Route path="/signin" element={<Signin/>} />
                        <Route path="/cookies" element={<Cookies/>} />
                        <Route path="/privacy" element={<Privacy/>} />
                        <Route path="/home" element ={<HomeFeed />} />
                    </Routes>
                </ScrollToTop>
            </AuthContextProvider>
        </Box>
    </BrowserRouter>
);

export default App