import { Stack, Typography, Button, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import { UserAuth } from '../context/AuthContext';

const Settings = () => {
  const { logOut, user } = UserAuth();

  const handleSignOut = async () => {
      try{
          await logOut();
          console.log("Successfully Signed Out");
      } catch (err) {
          console.error(err);
          alert(err.message);
      }
  };
  return (
    <Stack mx={1}>
      <Helmet>
          <title>YTForensics - Settings</title>
        </Helmet>
        <Stack direction="row">
        <IconButton onClick={() => window.history.back()} sx={{ mt: 1.5 }}>
          <ArrowBackIcon sx={{ color: '#FC1503' }}/>
        </IconButton>
        <Typography variant="h3" sx={{ mt: 1.5, color: '#FC1503'}}>
          {user?.displayName}
            </Typography>
        </Stack>
      <Link to={"https://myaccount.google.com/"} target="_blank" rel="noopener noreferrer" >
            <Typography variant="body2" sx={{ mt: 1.5, color: '#FFFFFF'}}>
              Account
            </Typography>
      </Link>
      <Link to="/cookies">
            <Typography variant="body2" sx={{ mt: 1.5, color: '#FFFFFF'}}>
              Cookies
            </Typography>
      </Link>
      <Link to="/privacy">
            <Typography variant="body2" sx={{ mt: 1.5, color: '#FFFFFF'}}>
              Privacy Policy
            </Typography>
      </Link>
      <Box sx={{display: 'flex', justifyContent: 'flex-start'}} >
      <Button sx={{color :  '#FC1503', mt: 3, borderColor : '#FC1503', '&:hover': {
                    borderColor: '#fff',
                    color: '#fff',
                }}} variant="outlined" onClick={handleSignOut}>Sign out</Button>
      </Box>
    </Stack>
  );
};

export default Settings;