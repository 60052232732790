import { useState, useEffect } from 'react';
import { Stack, Typography, Box } from '@mui/material';
import { NavSidebar, SearchSidebar, Videos, SearchBar, BottomBar } from './';
import { useParams } from "react-router-dom";
import { fetchFromAPI } from '../utils/fetchFromAPI';
import { Helmet } from 'react-helmet';

const SearchFeed = () => {
  
  const [selectedCategory, setSelectedCategory] = useState('Transcripts');

  const [videos, setVideos] = useState(null);

  const { dir } = useParams();

  const [searchTerm, setSearchTerm] = useState(null);

  const [numberOfResults, setNumberOfResults] = useState(null);

  useEffect(()=> {
    setVideos(null);
    setNumberOfResults(null);
    if (selectedCategory === 'Transcripts' && searchTerm !== undefined && searchTerm !== null){
      fetchFromAPI(`searchTranscripts/${dir}?searchStr=${searchTerm}`)
        .then((data) => {setVideos(data['Search results']); setNumberOfResults(data['Search count'])})
    } else if (selectedCategory === 'Comments' && searchTerm !== undefined && searchTerm !== null){
      fetchFromAPI(`searchComments/${dir}?searchStr=${searchTerm}`)
        .then((data) => {setVideos(data['Search results']); setNumberOfResults(data['Search count'])})
    } else if (selectedCategory === 'Titles' && searchTerm !== undefined && searchTerm !== null){
      fetchFromAPI(`searchVideoTitles/${dir}?searchStr=${searchTerm}`)
        .then((data) => {setVideos(data['Search results']); setNumberOfResults(data['Search count'])})
    } else if (selectedCategory === 'Descriptions' && searchTerm !== undefined && searchTerm !== null){
      fetchFromAPI(`searchVideoDescriptions/${dir}?searchStr=${searchTerm}`)
        .then((data) => {setVideos(data['Search results']); setNumberOfResults(data['Search count'])})
    } else if (searchTerm === null){
      fetchFromAPI(`getActivityDetails/${dir}`)
      .then((data) => {setVideos(data.items); })
    }
  },[dir, searchTerm, selectedCategory]);

  return (
    <Stack sx={{ pb: 7 }}>
        <Helmet>
          <title>YTForensics - Search</title>
        </Helmet>
        <NavSidebar />
        <SearchSidebar selectedCategory = {selectedCategory} setSelectedCategory = {setSelectedCategory}/>
        <SearchBar searchTerm = {searchTerm} setSearchTerm = {setSearchTerm}/>
                {searchTerm !== undefined && searchTerm !== null && numberOfResults === null && <Typography variant="h5"
                fontWeight = "bold" mx={4} mb={2} sx={{color :  'white'}}>
                Search {selectedCategory.toLowerCase()} for {searchTerm}
                </Typography>}
                {searchTerm !== undefined && searchTerm !== null && numberOfResults !== null && <Typography variant="h5"
                fontWeight = "bold" mx={4} mb={2} sx={{color :  'white'}}>
                Search {selectedCategory.toLowerCase()} for {searchTerm} found {numberOfResults} results
                </Typography>}
        <Box >
          <Videos videos={videos} />
        </Box>
        <BottomBar/>
    </Stack>
  );
};

export default SearchFeed;