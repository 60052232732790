import { Link } from 'react-router-dom';
import { Stack } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

export const NavSidebar = () => {

    return (
        <Stack
            direction="row"
            mt={2}
            ml={2}
            spacing={2}
            sx={{
                display:{ xs: 'none', md: 'flex' },
                justifyContent: 'flex-start',
                height: { sx: 'auto'},
                width: { sx: 'auto'}
            }}
        >
            <IconButton LinkComponent={Link} to={'/'} sx={{ mt: 1.5 }}>
                <HomeOutlinedIcon sx={{ color: '#FC1503' }}/>
            </IconButton>
            <IconButton LinkComponent={Link} to={'/settings'} sx={{ mt: 1.5 }}>
                <SettingsOutlinedIcon sx={{ color: '#FC1503' }}/>
            </IconButton>
        </Stack>
    );
};

export default NavSidebar;