import { initializeApp } from "firebase/app";
import { getAnalytics} from "firebase/analytics";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { GoogleAuthProvider } from "firebase/auth";
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from "firebase/app-check";
import { getPerformance } from "firebase/performance";
import Cookies from "js-cookie";

let cookieConsentValue = Cookies.get("CookieConsent") ?? "";
let cookieStatistics = cookieConsentValue.includes("statistics:true");
let cookieMarketing = cookieConsentValue.includes("marketing:true");
let cookiePreferences = cookieConsentValue.includes("preferences:true");

console.log("Cookie Consent Statistics: " + cookieStatistics);
console.log("Cookie Consent Marketing: " + cookieMarketing);
console.log("Cookie Consent Preferences: " + cookiePreferences);

const firebaseConfig = {
  apiKey: "AIzaSyAzPioA73qzkSOlnQuwfMGtVOj0Ty4SMDo",
  //authDomain: "subtle-well-388914.firebaseapp.com",
  authDomain: "analyse-everything.co.uk",
  projectId: "subtle-well-388914",
  storageBucket: "subtle-well-388914.appspot.com",
  messagingSenderId: "779479761962",
  appId: "1:779479761962:web:d7a320de7074328948ef53"
};

if (cookieMarketing)
  {
    firebaseConfig.measurementId = "G-MRXEEEH7C6"
  };

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
console.log("Firebase initialised");

// Create a ReCaptchaEnterpriseProvider instance using your reCAPTCHA Enterprise
// site key and pass it to initializeAppCheck().

// Initialize Firebase App Check using the ReCaptcha provider.

//remove this line after testing
if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
  window.self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
};

export const appCheck = initializeAppCheck(firebaseApp, {
  provider: new ReCaptchaEnterpriseProvider("6LeKXnUpAAAAAIvvPGo3lDkJfqb2EBtetn1KX7-v"),
  isTokenAutoRefreshEnabled: true // Set to true to allow auto-refresh.
});
console.log("Firebase App Check initialised");

export let analytics;
if (cookieMarketing)
  {
    analytics = getAnalytics(firebaseApp);
    console.log("Firebase Analytics initialised");
  };
export const auth = getAuth(firebaseApp);
if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
  connectAuthEmulator(auth, "http://127.0.0.1:9099");
}
console.log("Firebase Auth initialised");

export const googleProvider = new GoogleAuthProvider();

export let perf;
if (cookieStatistics)
  {
    perf = getPerformance(firebaseApp);
    console.log("Firebase Performance initialised");
  };




