import { Box, CircularProgress, Stack, Typography } from '@mui/material';

const Loader = () => {
  return (
    <Box minHeight="95vh">
    <Stack direction='column' justifyContent='center' alignItems='center' height='80vh' >
      <CircularProgress sx={{color:"red"}}/>
      <Typography variant="h5"
          fontWeight = "bold" mb={2} sx={{color :  'white'}}>
          Please wait...
        </Typography>
    </Stack>
    </Box>
  )
}

export default Loader