import {Stack, Box } from '@mui/material';

import VideoCard from './VideoCard';
import Loader from './Loader';

const Videos = ({ videos }) => {
  
  if(!videos?.length && videos?.length !== 0) return <Loader />;

  const filteredVideos = videos.filter(checkType);

  function checkType(item){
    return item.VideoID !== undefined || (item.id !== undefined && item.snippet.type === "upload")
  }

  return (
    <Stack flexWrap="wrap" justifyContent="center" gap={2} sx={{ flexDirection: { xs: "column", md: "row"} }}>    
      {filteredVideos.map((item, idx) => (
        <Box key={idx}>
          <VideoCard video={item} />
        </Box>
      ))}
    </Stack>
  )
}

export default Videos;