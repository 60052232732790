import {Link} from 'react-router-dom';
import {Typography, Card, CardContent, CardMedia, Box} from '@mui/material';
import {CheckCircle} from '@mui/icons-material';

const VideoCard = ({ video }) => {
  //console.log(video.id)

  var searchResults;
  if (video.Results !== null && video.Results !== undefined){
    if (video.Results.length === 1) {
        searchResults = <Typography variant="subtitle2" color="red">
        1 search result:
        </Typography>;
    } else if ((video.Results.length >= 1)){
        searchResults = <Typography variant="subtitle2" color="red">
        {video.Results.length} search results:
        </Typography>;
    }
  }

  var picture;
  if (video.Results !== null && video.Results !== undefined){
    picture = 
    <Link to={video['Video URL']} target="_blank" rel="noopener noreferrer">
    <CardMedia
      image={video['Thumbnail URL']}
      sx={{ width: { xs: '100%', sm: '358px', md: "358px" }, height: 180}}
    />
    </Link>
  } else {
    picture = 
    <Link to={"https://youtu.be/"+video.contentDetails.upload.videoId} target="_blank" rel="noopener noreferrer" >
    <CardMedia
      image={video?.snippet?.thumbnails?.high?.url}
      sx={{ width: { xs: '100%', sm: '358px', md: "358px" }, height: 180}}
    />
    </Link>
  }

  var content;
  if (video.Results !== null && video.Results !== undefined){
    content = 
    <CardContent sx={{ backgroundColor: "#1E1E1E", height: { xs: '100%', }}}>
      <Link to={video['Video URL']} target="_blank" rel="noopener noreferrer">
        <Typography variant="subtitle1" fontWeight="bold" color="#FFF">
          {video["Video Title"]}
        </Typography>
      </Link>
      <Typography variant="subtitle2" color="gray">
        {video['Channel Title']} <CheckCircle sx={{ fontSize: "12px", color: "gray", ml: "5px" }} />
      </Typography>
      {searchResults}
      <Typography variant="subtitle2" color="gray">
      {video?.Results[0]?.Description}
      </Typography>
      {video.Results.map((item, idx) => (
          <Box key={idx}>
            <Link to={item.Link} target="_blank" rel="noopener noreferrer" >
              <Typography variant="subtitle2" color="gray" sx={{textDecoration: 'underline'}}>
                {item.Text && idx + 1} {item.Text}
              </Typography>
            </Link>
          </Box>
      ))}
      {video.Results.map((item, idx) => (
        <Box key={idx}>
          <Link to={item['Comment URL']} target="_blank" rel="noopener noreferrer" >
            <Typography variant="subtitle2" color="gray">
              {item['Text original']}
            </Typography>
            </Link>
          <Link to={item['Author channel URL']} target="_blank" rel="noopener noreferrer" >
            <Typography variant="subtitle2" color="red">
              {item['Author display name']}
            </Typography>
          </Link>
        </Box>
      ))}
    </CardContent>
  } else {
    content = 
    <Link to={"https://youtu.be/"+video.contentDetails.upload.videoId} target="_blank" rel="noopener noreferrer" >
    <CardContent sx={{ backgroundColor: "#1E1E1E", height: { xs: '100%', }}}>
      <Typography variant="subtitle1" fontWeight="bold" color="#FFF">
        {video?.snippet?.title}
      </Typography>
      <Typography variant="subtitle2" color="gray">
        {video?.snippet?.channelTitle} <CheckCircle sx={{ fontSize: "12px", color: "gray", ml: "5px" }} />
      </Typography>
    </CardContent>
    </Link>
  }

  return (

    <Box
    sx={{
      boxShadow: 'none',
      display: 'flex',
      justifyContent: 'center',
      width: { xs: '358px', md: '320px' },
      height: 'auto',
      margin: 'auto',
    }}
    >

    <Card sx={{ boxShadow: "none", borderRadius: 0,  }}>
      {picture}
      {content}
    </Card>

    </Box>
  )
}

export default VideoCard