
import { Stack, Card, CardContent, CardMedia, Typography, Box, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import channelsImage from '../assets/Screenshot 2024-06-29 103135.png';
import searchTypeImage from '../assets/Screenshot 2024-06-29 103332.png';
import searchImage from '../assets/Screenshot 2024-06-29 103410.png';
import resultsImage from '../assets/Screenshot 2024-06-29 103549.png';
import resultsVideoImage from '../assets/Screenshot 2024-06-29 110653.png';
import resultsCommentImage from '../assets/Screenshot 2024-06-29 121738.png';

const HomeFeed = () => {
  
  return (
    <Box sx={{ width: '100%' }} alignItems={ 'center' }>
      <Stack>
        <Helmet>
          <title>YTForensics - Home</title>
        </Helmet>
      </Stack>
    <Stack mx={1} spacing= {10} direction="column" justifyContent="center" alignItems={ 'center' } sx={{ paddingBottom: '50px' }}>
        <Box sx={{ width: '345px', paddingTop: '10px' }}>
        <Button sx={{color :  '#FC1503', borderColor : '#FC1503', '&:hover': {
                    borderColor: '#fff',
                    color: '#fff',
                }}} variant="outlined" component={Link} to="/signin">Sign in</Button>
        <Typography variant="h3" component="div" sx={{ mt: 1, color: 'white'}}>
              Dive deeper into YouTube channels...
        </Typography>
        </Box>
        <Card sx={{ maxWidth: 345, backgroundColor: "black"  }}>
          <CardContent sx={{ backgroundColor: "#1E1E1E", height: { xs: '100%', }, borderRadius: '10px' }}>
            <Typography gutterBottom variant="h5" component="div" color="white">
              1. Select a channel
            </Typography>
            <Typography variant="body2" color="gray">
              You first need to select a YouTube channel to get started.
            </Typography>
          </CardContent>
          <CardMedia
            sx={{ width: 'auto', height: 79}}
            image={channelsImage}
            title="Channels"
          />
        </Card>

        <Card sx={{ maxWidth: 345, backgroundColor: "black"  }}>

          <CardContent sx={{ backgroundColor: "#1E1E1E", height: { xs: '100%', }, borderRadius: '10px' }}>
          <Typography gutterBottom variant="h5" component="div" color="white">
              2. Pick a type of search
            </Typography>
            <Typography variant="body2" color="gray">
              Choose the type of search you want to perform. You can choose from
              the following options:
                <li>Transcripts</li>
                <li>Comments</li>
                <li>Titles</li>
                <li>Descriptions</li>
            </Typography>
          </CardContent>
          <CardMedia
            sx={{ width: 'auto', height: 35}}
            image={searchTypeImage}
            title="Search Type"
          />
        </Card>

        <Card sx={{ maxWidth: 345, backgroundColor: "black"  }}>

          <CardContent sx={{ backgroundColor: "#1E1E1E", height: { xs: '100%', }, borderRadius: '10px' }}>
          <Typography gutterBottom variant="h5" component="div" color="white">
              3. Enter your search term
            </Typography>
            <Typography variant="body2" color="gray">
              Enter the word or phrase you want to search for in the YouTube
              channel. The search will be completed across all the videos on the channel.
            </Typography>
          </CardContent>
          <CardMedia
            sx={{ width: 'auto', height: 40}}
            image={searchImage}
            title="Search"
          />
        </Card>

        <Card sx={{ maxWidth: 345, backgroundColor: "black"  }}>

          <CardContent sx={{ backgroundColor: "#1E1E1E", height: { xs: '100%', }, borderRadius: '10px' }}>
          <Typography gutterBottom variant="h5" component="div" color="white">
              4. See the results
            </Typography>
            <Typography variant="body2" color="gray">
              All the results for your search term will be displayed.
            </Typography>
          </CardContent>
          <CardMedia
            sx={{ width: 'auto', height: 113}}
            image={resultsImage}
            title="Results"
          />
        </Card>

        <Card sx={{ maxWidth: 345, backgroundColor: "black"  }}>

          <CardContent sx={{ backgroundColor: "#1E1E1E", height: { xs: '100%', }, borderRadius: '10px' }}>
          <Typography gutterBottom variant="h5" component="div" color="white">
              5. Launch the video on YouTube
            </Typography>
            <Typography variant="body2" color="gray">
              If you searched transcripts, you can click on a search result to start the video at the exact point the search term appears in the video.
            </Typography>
          </CardContent>
          <CardMedia
            sx={{ width: 'auto', height: 229, borderRadius: '10px'}}
            image={resultsVideoImage}
            title="Results Video"
          />
        </Card>

        <Card sx={{ maxWidth: 345, backgroundColor: "black" }}>

          <CardContent sx={{ backgroundColor: "#1E1E1E", height: { xs: '100%', }, borderRadius: '10px' }}>
          <Typography gutterBottom variant="h5" component="div" color="white">
              6. View the comment on YouTube
            </Typography>
            <Typography variant="body2" color="gray">
               If you searched comments, you can click on the result to take you to the comment on YouTube.
            </Typography>
          </CardContent>
          <CardMedia
            sx={{ width: 'auto', height: 66}}
            image={resultsCommentImage}
            title="Results Comment"
          />
        </Card>
    </Stack>
    </Box>

  );
};

export default HomeFeed;