import { Stack, Typography } from '@mui/material';
import { Helmet } from 'react-helmet';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';

const Privacy = () => {
  
  return (
    <Stack mx={1}>
      <Helmet>
          <title>YTForensics - Privacy</title>
        </Helmet>
        <Stack direction="row">
        <IconButton onClick={() => window.history.back()} sx={{ mt: 1.5 }}>
          <ArrowBackIcon sx={{ color: '#FC1503' }}/>
        </IconButton>
        <Typography variant="h3" sx={{ mt: 1.5, color: '#FC1503'}}>
              Privacy Policy
            </Typography>
        </Stack>
            <Typography variant="body2" color='white'>
<p>We, at Analyse Everything Ltd, are dedicated to serving our customers and contacts to the best of our abilities. Part of our commitment involves the responsible management of personal information collected through our website(s) (analyse-everything.co.uk), and any related interactions.</p>
<p>Our primary goals in processing this information include:</p>
<ul>
<li>Enhancing the user experience on our platform by understanding customer needs and preferences.</li>
<li>Providing timely support and responding to inquiries or service requests.</li>
<li>Improving our products and services to meet the evolving demands of our users.</li>
<li>Conducting necessary business operations, such as billing and account management.</li>
</ul>
<p>It is our policy to process personal information with the utmost respect for privacy and security. We adhere to all relevant regulations and guidelines to ensure that the data we handle is protected against unauthorized access, disclosure, alteration, and destruction. Our practices are designed to safeguard the confidentiality and integrity of your personal information, while enabling us to deliver the services you trust us with.</p>
<p>We do not not have a designated Data Protection Officer (DPO) but remain fully committed to addressing your privacy concerns. Should you have any questions or require further information about how we manage personal information, please feel free to contact us at privacy@analyse-everything.co.uk.</p>
<p>Your privacy is our priority. We are committed to processing your personal information transparently and with your safety in mind. This commitment extends to our collaboration with third-party services that may process personal information on our behalf, such as in the case of sending invoices. Rest assured, all activities are conducted in strict compliance with applicable privacy laws.</p>
<p>Our privacy policy is designed to protect the personal information of all our stakeholders, including website visitors, registered users, and customers. Whether you are just browsing our websites (analyse-everything.co.uk), using our services as a registered user, or engaging with us as a valued customer, we ensure that your personal data is processed with the highest standards of privacy and security. This policy outlines our practices and your rights related to personal information.</p>
<p>Our commitment to transparency and data protection extends to how we collect and use your personal information. We gather personal data through various interactions, including but not limited to, when you utilize our services (YouTube search functionality), purchase our products (YTForensics), or directly provide information to us.</p>
<p>The following list details the types of personal information we may process:</p>
<ul>
<li>First and Last Name: To personalize our communication and services.</li>
<li>Telephone Number and Email Address: Essential for direct communication and support.</li>
<li>Information about Your Activity on Our Website: Helps us improve website usability and service offerings.</li>
</ul>
<p>Please note, we only process information that is essential for delivering our services, complying with legal obligations, or enhancing your user experience. Your privacy is paramount, and we are dedicated to handling your personal information responsibly and in accordance with all applicable laws.</p>
<p>At Analyse Everything Ltd, we believe in using personal information responsibly and ethically. The data we collect serves multiple purposes, all aimed at enhancing the services we offer and ensuring the highest level of satisfaction among our users, customers, and employees. Here are the key ways in which we use the personal information collected:</p>
<ul>
<li>To Provide and Improve Our Services: Your information helps us tailor our offerings to meet your needs more effectively. Personal details like your name, email address, and activity on our website enable us to personalize your experience and offer relevant content.</li>
<li>Security and Fraud Prevention: Data such as IP addresses, location details, and browser fingerprints are used to enhance the security of our services, protecting both you and us from fraudulent activities.</li>
</ul>
<p>Your privacy is our priority. We process your personal information transparently and in accordance with your preferences and applicable privacy laws. We are committed to ensuring that your data is used solely for the purposes for which it was collected and in ways that you have authorized.</p>
<p>At Analyse Everything Ltd, the security of your personal information is paramount. We employ a variety of measures to protect the integrity and confidentiality of the data we collect. Below, we outline our practices regarding data storage and protection:</p>
<h2>Data Storage</h2>
<ul>
<li>Location: Personal information is stored in secure servers located in United States. For services that require international data transfer, we ensure that such transfers comply with all applicable laws and maintain data protection standards equivalent to those in our primary location.</li>
<li>Data Hosting Partners: We partner with reputable data hosting providers committed to using state-of-the-art security measures. These partners are selected based on their adherence to stringent data protection standards.</li>
</ul>
<h2>Data Protection Measures</h2>
<ul>
<li>Encryption: To protect data during transfer and at rest, we employ robust encryption technologies.</li>
<li>Access Control: Access to personal information is strictly limited to authorized personnel who have a legitimate business need to access the data. We enforce strict access controls and regularly review permissions.</li>
<li>Security Audits and Monitoring: Regular security audits are conducted to identify and remediate potential vulnerabilities. We also monitor our systems for unusual activities to prevent unauthorized access.</li>
</ul>
<h2>Data Retention</h2>
<ul>
<li>Retention Period: We retain personal information only for as long as necessary to fulfill the purposes for which it was collected, including for the purposes of satisfying any legal, accounting, or reporting requirements. Specific retention periods depend on the nature of the information and the purposes for processing it.</li>
<li>Deletion Policy: Once the retention period expires, personal data is securely deleted or anonymized, so it can no longer be associated with an individual.</li>
</ul>
<p>Your privacy and data security are our top priorities. We are committed to handling your personal information with care and protecting it from misuse, interference, loss, unauthorized access, modification, or disclosure. If you have any questions about how we store and protect your personal information, please contact us at info@analyse-everything.co.uk.</p>
<p>We continually review and update our security practices in response to evolving threats and advancements in technology to ensure the highest level of protection for your personal information.</p>
<p>At Analyse Everything Ltd, we are committed to safeguarding your personal information and ensuring it is treated with the utmost respect. This commitment extends to how we handle the sharing and disclosure of your data. Below we outline our practices in this area:</p>
<h2>Sharing Personal Information</h2>
<p>Third-Party Service Providers: We may share your information with Alphabet (Google), Meta (Facebook), Microsoft, Amazon, ByteDance, Apple who perform services on our behalf, including but not limited to payment processing, data analysis, email delivery, hosting services, customer service, and marketing assistance. These partners are prohibited from using your personal information for any purpose other than to provide these services to Analyse Everything Ltd, and they are required to maintain the confidentiality of your information.</p>
<p>Legal Requirements and Law Enforcement: Under certain circumstances, Analyse Everything Ltd may be required to disclose your personal information if required by law or in response to valid requests by public authorities (e.g., a court or a government agency). Additionally, we may disclose your personal information in the good faith belief that such action is necessary to:</p>
<ul>
<li>Comply with a legal obligation</li>
<li>Protect and defend the rights or property of Analyse Everything Ltd</li>
<li>Prevent or investigate possible wrongdoing in connection with the Service</li>
<li>Protect the personal safety of users of the Service or the public</li>
<li>Protect against legal liability</li>
</ul>
<h2>Data Processing Agreements</h2>
<p>When we share your data with third-party service providers, we do so under the protection of Data Processing Agreements (DPAs) that ensure your information is managed in accordance with GDPR and other relevant data protection laws. These agreements mandate that third parties implement adequate technical and organizational measures to ensure the security of your data.</p>
<h2>Transparency and Control</h2>
<p>We believe in transparency and providing you with control over your personal information. You will always be informed about any significant changes to our sharing practices, and where applicable, you will have the option to consent to such changes.</p>
<p>Your trust is important to us, and we strive to ensure that your personal information is disclosed only in accordance with this policy and when there is a justified reason to do so. For any queries or concerns about how we share and disclose personal information, please reach out to us at info@analyse-everything.co.uk.</p>
<p>At Analyse Everything Ltd, we recognize and respect your rights regarding your personal information, in accordance with the General Data Protection Regulation (GDPR) and other applicable data protection laws. We are committed to ensuring you can exercise your rights effectively. Below is an overview of your rights and how you can exercise them:</p>
<h2>Your Rights</h2>
<ul>
<li>Right of Access (Article 15 GDPR): You have the right to request access to the personal information we hold about you and to obtain information about how we process it.</li>
<li>Right to Rectification (Article 16 GDPR): If you believe that any personal information we hold about you is incorrect or incomplete, you have the right to request its correction or completion.</li>
<li>Right to Erasure (‘Right to be Forgotten’) (Article 17 GDPR): You have the right to request the deletion of your personal information when it is no longer necessary for the purposes for which it was collected, among other circumstances.</li>
<li>Right to Restriction of Processing (Article 18 GDPR): You have the right to request that we restrict the processing of your personal information under certain conditions.</li>
<li>Right to Data Portability (Article 20 GDPR): You have the right to receive your personal information in a structured, commonly used, and machine-readable format and to transmit those data to another controller.</li>
<li>Right to Object (Article 21 GDPR): You have the right to object to the processing of your personal information, under certain conditions, including processing for direct marketing.</li>
<li>Right to Withdraw Consent (Article 7(3) GDPR): Where our processing of your personal information is based on your consent, you have the right to withdraw that consent at any time without affecting the lawfulness of processing based on consent before its withdrawal.</li>
<li>Right to Lodge a Complaint (Article 77 GDPR): You have the right to lodge a complaint with a supervisory authority if you believe our processing of your personal information violates applicable data protection laws.</li>
</ul>
<h2>Exercising Your Rights</h2>
<p>To exercise any of these rights, please contact us at privacy@analyse-everything.co.uk. We will respond to your request in accordance with applicable data protection laws and within the timeframes stipulated by those laws. Please note, in some cases, we may need to verify your identity as part of the process to ensure the security of your personal information.</p>
<p>We are committed to facilitating the exercise of your rights and to ensuring you have full control over your personal information. If you have any questions or concerns about how your personal information is handled, please do not hesitate to get in touch with us.</p>
<p>At Analyse Everything Ltd, we value your privacy and are committed to being transparent about our use of cookies and other tracking technologies on our website . These technologies play a crucial role in ensuring the smooth operation of our digital platforms, enhancing your user experience, and providing insights that help us improve.</p>
<h2>Understanding Cookies and Tracking Technologies</h2>
<p>Cookies are small data files placed on your device that enable us to remember your preferences and collect information about your website usage. Tracking technologies, such as web beacons and pixel tags, help us understand how you interact with our site and which pages you visit.</p>
<h2>How We Use These Technologies</h2>
<ul>
<li>Essential Cookies: Necessary for the website's functionality, such as authentication and security. They do not require consent.</li>
<li>Performance and Analytics Cookies: These collect information about how visitors use our website, which pages are visited most frequently, and if error messages are received from web pages. These cookies help us improve our website.</li>
<li>Functional Cookies: Enable the website to provide enhanced functionality and personalization, like remembering your preferences.</li>
<li>Advertising and Targeting Cookies: Used to deliver advertisements more relevant to you and your interests. They are also used to limit the number of times you see an advertisement and help measure the effectiveness of the advertising campaign.</li>
</ul>
<h2>Your Choices and Consent</h2>
<p>Upon your first visit, our website will present you with a cookie consent banner, where you can:</p>
<ul>
<li>Accept All Cookies: Consent to the use of all cookies and tracking technologies.</li>
<li>Reject Non-Essential Cookies: Only essential cookies will be used to provide you with necessary website functions.</li>
<li>Customize Your Preferences: Choose which categories of cookies you wish to allow.</li>
</ul>
<p>You can change your cookie preferences at any time through our Cookie settings feature. Additionally, your web browser allows you to control and limit cookies on your device.</p>
<h2>Changes to Our Cookie Use</h2>
<p>We may update our use of cookies and tracking technologies to improve our services or comply with legal requirements. We will notify you of any significant changes and seek your consent where necessary.</p>
<p>For more detailed information about the cookies we use, their purposes, and how you can manage your preferences, please visit our detailed Cookie Policy.</p>
<p>Should you have any questions or concerns about our use of cookies and tracking technologies, please do not hesitate to contact us at info@analyse-everything.co.uk. Your privacy and the integrity of your personal data are of utmost importance to us.</p>
<p>At Analyse Everything Ltd, we may transfer your personal information to locations outside of your country of residence, including to countries that may have different data protection laws than those in your jurisdiction. We want to assure you that any such transfers are conducted with the utmost care and in compliance with applicable data protection regulations, including the General Data Protection Regulation (GDPR).</p>
<h2>Legal Basis for International Data Transfers</h2>
<p>As explained in this privacy policy, we use services whose providers are partly located in so-called third countries (outside the European Union or the European Economic Area) or process personal data there, i.e. countries whose level of data protection does not correspond to that of the European Union. Where this is the case and the European Commission has not issued an adequacy decision (Art. 45 GDPR) for these countries, we have taken appropriate precautions to ensure an adequate level of data protection for any data transfers. These include, among others, the standard contractual clauses of the European Union or binding internal data protection regulations.</p>
<p>If a third country transfer is provided for and no adequacy decision or appropriate safeguards are in place, it is possible and there is a risk that authorities in the respective third country (e.g. intelligence services) may gain access to the transferred data in order to collect and analyze it, and that enforceability of your data subject rights cannot be guaranteed.</p>
<h2>Your Rights and Protections</h2>
<p>Your rights and protections regarding the processing and transfer of your personal information remain unaffected by any international data transfers. We are committed to ensuring that your data is treated securely and in accordance with this privacy policy, regardless of where it is processed or stored.</p>
<h2>Questions and Contact Information</h2>
<p>If you have any questions or concerns about international data transfers or how your personal information is handled, please contact us at info@analyse-everything.co.uk. We are here to provide you with the information and support you need to feel confident about the security and integrity of your personal data.</p>
<p>At Analyse Everything Ltd, we are committed to protecting the privacy of children who use our services. We recognize the importance of safeguarding the personal information of minors, and we take steps to ensure compliance with applicable laws and regulations, including the Children's Online Privacy Protection Act (COPPA) in the United States and similar regulations in other jurisdictions.</p>
<p>At Analyse Everything Ltd, we may use your personal information to send you direct marketing communications about our products, services, promotions, and other relevant information that we believe may be of interest to you. We are committed to ensuring that our direct marketing practices are transparent, lawful, and in compliance with applicable data protection laws, including the General Data Protection Regulation (GDPR) and the ePrivacy Directive.</p>
<h2>Managing Your Preferences</h2>
<p>You have control over the direct marketing communications you receive from us. You can manage your communication preferences by:</p>
<p>Contacting us directly at privacy@analyse-everything.co.uk to update your preferences or opt-out of specific marketing channels.</p>
<h2>Compliance with Data Protection Laws</h2>
<p>Our direct marketing practices are conducted in accordance with applicable data protection laws, including the GDPR and the ePrivacy Directive. We respect your rights and preferences regarding direct marketing communications and are committed to ensuring that our marketing activities are conducted responsibly and ethically.</p>
<h2>Contact Us</h2>
<p>If you have any questions or concerns about our direct marketing practices or wish to update your communication preferences, please contact us at info@analyse-everything.co.uk. We are here to assist you and ensure that your preferences regarding direct marketing communications are respected.</p>
<p>At Analyse Everything Ltd, we understand the importance of protecting your personal information and take proactive measures to safeguard it. In the event of a data breach that poses a risk to your privacy rights and freedoms, we have established clear procedures for promptly identifying, assessing, and mitigating the impact of the breach. Our data breach notification procedures are designed to comply with applicable data protection laws and regulations, including the General Data Protection Regulation (GDPR).</p>
<h2>Detection and Assessment</h2>
<ul>
<li>Internal Monitoring: We employ robust security measures and monitoring systems to detect and respond to potential data breaches promptly.</li>
<li>Assessment of Breach Impact: Upon discovery of a data breach, we will conduct a thorough assessment to determine the nature and scope of the breach, including the types of personal information involved and the potential impact on affected individuals.</li>
</ul>
<h2>Notification Obligations</h2>
<p>Regulatory Authorities: If required by law, we will notify the relevant data protection authorities of the data breach within 72 hours, following the procedures specified by applicable regulations.</p>
<p>Affected Individuals: If a data breach poses a significant risk to your privacy rights and freedoms, we will notify you within 72 hours, providing clear and concise information about the breach, the types of personal information affected, and the steps you can take to protect yourself.</p>
<h2>Communication Channels</h2>
<ul>
<li>Email Notification: We may notify affected individuals via email, using the contact information provided to us, if feasible and appropriate.</li>
</ul>
<h2>Support and Assistance</h2>
<ul>
<li>Support Resources: In the event of a data breach, we are committed to providing affected individuals with the support and assistance they need, including guidance on steps they can take to mitigate the potential risks associated with the breach.</li>
<li>Point of Contact: If you have any questions or concerns about a data breach or believe you may have been affected, please contact us immediately at info@analyse-everything.co.uk.</li>
</ul>
<p>At Analyse Everything Ltd, we are committed to upholding the highest standards of data protection and privacy. We recognize the importance of accountability and compliance with data protection regulations, such as the General Data Protection Regulation (GDPR), in safeguarding your personal information. To demonstrate our commitment, we have implemented the following accountability and compliance measures:</p>
<p>At Analyse Everything Ltd, we take data protection and privacy matters seriously. We are committed to ensuring that your privacy concerns are addressed promptly and effectively. Should you have any questions or concerns about how we handle your personal information, please don't hesitate to contact us using the information provided below:</p>
<h2>Contact Information for Privacy Concerns</h2>
<ul>
<li>info@analyse-everything.co.uk</li>
<li>privacy@analyse-everything.co.uk</li>
</ul>
<p>Our dedicated team is here to assist you with any privacy-related inquiries or concerns you may have. Whether you have questions about our privacy practices, wish to exercise your data protection rights, or need assistance with a privacy-related issue, we are here to help.</p>
<p>We strive to provide multiple channels for you to reach out to us with your privacy concerns, ensuring that you can communicate with us in a way that is convenient and comfortable for you.</p>
<h2>Data Protection Oversight</h2>
<p>While we do not have a designated Data Protection Officer (DPO) for our services, we have internal processes and procedures in place to ensure that your personal information is handled in compliance with applicable data protection laws and regulations. Our team is trained to handle privacy matters effectively and to address any privacy-related issues that may arise.</p>
<p>At Analyse Everything Ltd, we are committed to keeping you informed about how we handle your personal information and any changes to our privacy practices. We may update this privacy policy from time to time to reflect changes in legal requirements, industry standards, or our business operations. We want to assure you that any updates will be communicated transparently and in accordance with applicable data protection laws.</p>
<h2>Contact Us</h2>
<p>If you have any questions or concerns about our privacy policy or any updates to it, please don't hesitate to contact us at info@analyse-everything.co.uk. We are here to address any inquiries you may have and to ensure that you have the information you need to feel confident about how your personal information is handled.</p>
</Typography>
    </Stack>
  );
};

export default Privacy;