import { useState } from 'react';
import { Paper, IconButton, Stack} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const SearchBar = ({searchTerm, setSearchTerm}) => {

  const [searchQuery, setSearchQuery] = useState('');

  const onhandleSubmit = (e) => {
    e.preventDefault();

    if (searchQuery) {
      setSearchTerm(searchQuery);

      setSearchQuery('');
    }
  };

  return (
    <Stack direction="row" flexWrap="wrap" justifyContent="center">
      <Paper
        component='form'
        name='searchForm'
        onSubmit={onhandleSubmit}
        sx={{
          width: { md: "100%"},
          borderRadius: 20,
          border: '1px solid #e3e3e3',
          pl: 2,
          boxShadow: 'none',
          marginBottom: 2,
          ml: 2,
          mr: 2
        }}
      >
        <input
          name="searchInput"
          className='search-bar'
          placeholder='Search...'
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <IconButton type='submit' sx={{ p: '10px', color: 'red' }} aria-label='search'>
          <SearchIcon />
        </IconButton>
      </Paper>
    </Stack>
  );
};

export default SearchBar;