
import { Stack, Typography, Box} from "@mui/material";
import { useEffect } from 'react';
import { GoogleButton } from 'react-google-button';
import { useNavigate } from 'react-router-dom';
import { UserAuth } from '../context/AuthContext';
import { Helmet } from 'react-helmet';

export const Signin = () => {
    const { googleSignIn, user } = UserAuth();
    const navigate = useNavigate();
  
    const handleGoogleSignIn = async () => {
      try {
        await googleSignIn();
      } catch (error) {
        console.log(error);
      }
      };
  
    useEffect(() => {
      if (user != null) {
        navigate('/');
      }
    }, [user, navigate]);

    return (
        <Stack mt={2}>
          <Helmet>
            <title>YTForensics - Sign In</title>
          </Helmet>
          <Box
          display="flex"
          justifyContent="center"
          minHeight="auto"
          >
            <Typography variant="h5"
            fontWeight = "bold" mb={2} sx={{color :  '#FC1503'}}>
            Sign in to YTForensics
            </Typography>
          </Box>
          <Box
          display="flex"
          justifyContent="center"
          minHeight="auto"
          >
            <div className='max-w-[240px] m-auto py-4'>
                <GoogleButton onClick={handleGoogleSignIn}/>
            </div>
          </Box>
        </Stack>
    );
};

export default Signin;
