import {Stack, Box } from '@mui/material';

import Loader from './Loader';
import ChannelCard from './ChannelCard';

const Channels = ({ channels }) => {
  
  if(!channels?.length) return <Loader />;

  return (
    <Stack flexWrap="wrap" justifyContent="center" gap={2} sx={{ flexDirection: { xs: "column", md: "row"} }}> 
      {channels.map((item, idx) => (
        <Box key={idx}>
          <ChannelCard channelDetail={item}/>
        </Box>
      ))}
    </Stack>
  )
}

export default Channels;